import React from 'react'
import styles from './Anons.module.scss'

function Anons() {
  return (
    <div className={styles.anons} id="lectures">
      <div className="app-content">
        <div className="container">
          <h1>Анонс</h1>
          <div className={styles.date}>03 октября 2024 года</div>
          <div className={styles.lectures}>
            <div className={styles.item}>
              <div className={styles.meta}>
                <div className={styles.number}>01</div>
              </div>
              <div className={styles.content}>
                <div className={styles.author}>
                  <div className={styles.name}>Олег Чирухин</div>
                  <div className={styles.position}>владелец продукта GIGA IDE Cloud в Сбертехе</div>
                </div>
                <div className={styles.description}>
                  <p>GIGA IDE Cloud - сверхновая IDE во вселенной веб-разработки</p>
                  Раньше для веба вполне хватало vim и emacs. SSH прямо на прод, открываешь
                  index.php... Сейчас, попробуй разберись в 200 микросервисах. На GitVerse прямо
                  сейчас создается сверхновая IDE, которая может засосать все эти наши проблемы, с
                  помощью облаков и AI. Потихоньку, полегоньку, выходят первые фичи и
                  кристаллизуется платформа. Одновременно, корабль отправляется в путешествие к
                  новым мирам - микросервисам, монорепозиториям, фулл риалтайм разработке - они
                  становятся first class citizens, о них теперь принято думать и заботиться.
                </div>
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.meta}>
                <div className={styles.number}>02</div>
              </div>
              <div className={styles.content}>
                <div className={styles.author}>
                  <div className={styles.name}>Станислав Ракчаев</div>
                  <div className={styles.position}></div>
                </div>
                <div className={styles.description}>
                  <p>
                    Transaction Script и Transaction Outbox в PHP: Эффективные паттерны для
                    управления транзакциями
                  </p>
                  В современном мире веб-приложений управление транзакциями играет ключевую роль в
                  обеспечении надежности и согласованности данных. При разработке на PHP важно
                  выбирать правильные архитектурные подходы для обработки транзакций и интеграции с
                  различными сервисами. В данном докладе мы рассмотрим два мощных паттерна -
                  Transaction Script и Transaction Outbox. Вы узнаете, как они работают, в каких
                  сценариях применимы, а также получите практические рекомендации по их реализации в
                  PHP-проектах.
                </div>
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.meta}>
                <div className={styles.number}>03</div>
              </div>
              <div className={styles.content}>
                <div className={styles.author}>
                  <div className={styles.name}>Кирилл Несмеянов</div>
                  <div className={styles.position}></div>
                </div>
                <div className={styles.description}>
                  <p>Страх и ненависть PSR</p>
                  PSR, как и любой стандарт, был во время своего появления благом, однако подобные
                  рекомендации были написаны давно и без учёта экосистемы и современных реалий.
                  <br />
                  <br />
                  Попробуем разобраться: где в PSR были допущены ошибки проектирования, почему
                  рекомендации устарели, какие проблемы скрывают и почему большинство рекомендаций
                  могут добавить проблем, нежели помочь.
                </div>
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.meta}>
                <div className={styles.number}>04</div>
              </div>
              <div className={styles.content}>
                <div className={styles.author}>
                  <div className={styles.name}>Дмитрий Елисеев</div>
                  <div className={styles.position}></div>
                </div>
                <div className={styles.description}>
                  <p>Как растут проекты</p>
                  Решили разрабатывать новый проект, может даже по TDD и DDD, но заказчик сам не
                  знает что ему нужно и не даёт чёткое ТЗ? Не переживайте. Это не баг, а фича.
                  Разбираемся почему так, что делать с непонятными проектами и какие подходы и
                  паттерны в этом нам помогут.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Anons
